import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { up } from 'styled-breakpoints';
import { v4 as uuidv4 } from 'uuid';
import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase';
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg';
import CertGeneration from '@babelcoder/gatsby-theme-courses/src/components/courses/CertGeneration';

import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme';
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';

const Wrapper = styled.div`
  position: relative;
  display: flow-root;
  margin: ${courseTheme.gutter} auto 0 auto;

  ${up('large')} {
    padding: 0 ${courseTheme.gutter};
  }
`;

const Button = styled.div`
  cursor: pointer;
  ${({ theme }) => css`
    border-radius: ${theme.round.large};
    background-color: ${theme.colors.main.primary};
    color: ${theme.colors.neutral.white};
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    margin: 0 auto;
    width: max-content;
  `}
`;

const Title = styled.h2`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

const Placeholder = styled.p`
  text-align: center;
`;

const EnrollLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.main.primary};
    text-decoration: underline;
  `}
`;

function Certification({ slug, name, title, verifiedAt, liveTime }) {
  const [cert, setCert] = useState();
  const [currentCert, setCurrentCert] = useState();
  const [registration, setRegistration] = useState();
  const [isRegisteredLoading, setIsRegisteredLoading] = useState(true);
  const { user, fullName } = useGlobalState();
  const { getCoursePath } = useCoursesPath();
  const loadCert = useCallback(async () => {
    if (!user) return;
    const firestore = await getFirebaseFirestore();
    const certSnapshot = await firestore
      .doc(`users/${user.uid}/certs/${slug}`)
      .get();
    const certData = certSnapshot.data();

    if (certData) setCert(certData);
  }, [user, slug]);

  const loadCurrentCert = useCallback(async () => {
    if (!user) return;
    if (!cert) return;

    const firestore = await getFirebaseFirestore();
    const certSnapshot = await firestore.doc(`certs/${cert.id}`).get();
    const certData = certSnapshot.data();

    if (certData) setCurrentCert(certData);
  }, [user, cert]);

  const loadRegistration = useCallback(async () => {
    if (!user) return;

    setIsRegisteredLoading(true);
    const firestore = await getFirebaseFirestore();
    const registrationSnapshot = await firestore
      .doc(`users/${user.uid}/registrations/${slug}`)
      .get();
    const registrationData = registrationSnapshot.data();
    if (registrationData) setRegistration(registrationData);
    setIsRegisteredLoading(false);
  }, [user, slug]);

  const receiveCert = useCallback(async () => {
    const firestore = await getFirebaseFirestore();
    const id = uuidv4();
    const data = {
      id,
      uid: user.uid,
      certificationTitle: title,
      courseDate: liveTime,
      name: fullName,
      verifiedAt,
      courseName: name,
      courseSlug: slug,
      createdAt: new Date(),
    };
    await firestore.doc(`certs/${id}`).set(data);
    setCurrentCert(data);
    await firestore.doc(`users/${user.uid}/certs/${slug}`).set({ id });
  }, [title, liveTime, name, slug, verifiedAt, user, fullName]);

  useEffect(() => {
    loadRegistration();
    loadCert();
  }, [loadRegistration, loadCert]);

  useEffect(() => {
    loadCurrentCert();
  }, [loadCurrentCert]);

  return (
    <Wrapper>
      <Title>
        ใบรับรองการเข้าอบรมหลักสูตร <br />
        {title}
      </Title>
      {isRegisteredLoading ? (
        <LoadingImage></LoadingImage>
      ) : registration?.success && registration.courseType === 'enterprise' ? (
        currentCert ? (
          <CertGeneration
            certification={currentCert}
            downloadable
          ></CertGeneration>
        ) : fullName ? (
          <Button onClick={receiveCert}>ขอรับใบรับรอง</Button>
        ) : (
          <Placeholder>
            กรุณา{' '}
            <EnrollLink to="/auth/profile">
              ระบุชื่อ-นามสกุล ในหน้าโปรไฟล์
            </EnrollLink>{' '}
            ก่อนขอใบรับรองหลักสูตรนี้
          </Placeholder>
        )
      ) : registration ? (
        <Placeholder>
          เฉพาะผู้ลงทะเบียนแบบคอร์สอบรมระดับองค์กรเท่านั้นที่สามารถขอใบรับรองนี้ได้
        </Placeholder>
      ) : (
        <Placeholder>
          กรุณา <EnrollLink to={getCoursePath(slug)}>ลงทะเบียน</EnrollLink>{' '}
          ก่อนขอใบรับรองหลักสูตรนี้
        </Placeholder>
      )}
    </Wrapper>
  );
}

export default Certification;

import React from 'react';
import { graphql } from 'gatsby';

import Certification from '../../components/courses/enterprise/Certification';

export default function CertificationTemplate({ data: { course } }) {
  return <Certification {...course}></Certification>;
}

CertificationTemplate.layout = {
  headerType: 'fixed',
  toggleable: true,
  hasFooter: false,
};

export const pageQuery = graphql`
  query EnterpriseCertification($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      name
      title
      verifiedAt
      liveTime
    }
  }
`;
